import localforage from "localforage";

// Set a custom driver order
localforage.setDriver([
  localforage.LOCALSTORAGE,
  // localforage.INDEXEDDB,
  // localforage.WEBSQL,
]);

// Set the name of the database
localforage.config({
  name: "hijaz_world",
});

export default localforage;
