<template>
  <div class="border border-green rounded-full p-0.5" :class="size">
    <button
      class="rounded-full text-white font-bold w-full h-full"
      :class="[bgColor]"
    >
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    bgColor: String,
    size: {
      type: String,
      default: "w-72 h-16",
    },
  },
};
</script>
