import { createRouter, createWebHistory } from "vue-router";

const HomeView = () =>
  import(/* webpackChunkName: "HomepageView"  */ "../views/HomePageView.vue");

const AboutView = () =>
  import(/* webpackChunkName: "AboutView"  */ "@/views/AboutView.vue");

const CoursesView = () =>
  import(/* webpackChunkName: "CoursesView"  */ "@/views/CoursesView.vue");

const ShopView = () =>
  import(/* webpackChunkName: "ShopView"  */ "@/views/ShopView/ShopView.vue");

const ContactUs = () =>
  import(/* webpackChunkName: "ContactUs"  */ "@/views/ContactUs.vue");

const DetailView = () =>
  import(
    /* webpackChunkName: "DetailView"  */ "@/views/ShopView/DetailView.vue"
  );

const ProductCategoryView = () =>
  import(
    /* webpackChunkName: "ProductCategoryView"  */ "@/views/ShopView/ProductCategoryView.vue"
  );

const MainLayout = () =>
  import(/* webpackChunkName: "MainLayout"  */ "@/layouts/MainLayout.vue");

const PageNotFound = () =>
  import(/* webpackChunkName: "PageNotFoundView"  */ "@/views/PageNotFound");

import { metaTagsUpdate } from "@/router/metaTagsUpdate";
import { scrollToHashElement } from "@/router/scrollToHashElement";

const routes = [
  {
    path: "/",
    name: "home-page",
    component: MainLayout,
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
          title: "Home",
        },
      },
      {
        path: "/about",
        name: "about",
        component: AboutView,
        meta: {
          title: "About",
        },
      },
      {
        path: "/courses",
        name: "courses",
        component: CoursesView,
        meta: {
          title: "Courses",
        },
      },
      {
        path: "/shop",
        name: "shop",
        component: ShopView,
        meta: {
          title: "Shop",
        },
      },
      {
        path: "/contact-us",
        name: "contact-us",
        component: ContactUs,
        meta: {
          title: "Contact Us",
        },
      },
      {
        path: "/product-detail/:id",
        name: "product-detail",
        component: DetailView,
        meta: {
          title: "Product Detail",
        },
      },
      {
        path: "/product-category/:id",
        name: "product-category",
        component: ProductCategoryView,
        meta: {
          title: "Product Category",
        },
      },
      {
        path: "/404",
        name: "pageNotFound",
        component: PageNotFound,
      },
      {
        path: "/:catchAll(.*)",
        redirect: "/404",
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: scrollToHashElement,
});

router.beforeEach((to, from, next) => {
  metaTagsUpdate(to, from, next);
});

export default router;
