import { createApp } from "vue";
import App from "./App.vue";
import { createI18n } from "vue-i18n";
import { langMessages } from "./lang/messages";
import localforage from "./localForage";
import VueGtag from "vue-gtag";

const app = createApp(App);

export default {
  initGA() {
    app.use(VueGtag, {
      config: { id: process.env.VUE_APP_GA_KEY_WEBSITE },
    });
  },
  initlocalForage() {
    app.use(localforage);
  },
  initLangModule() {
    app.use(createI18n);

    return new createI18n({
      locale: "en",
      fallbackLocale: "en",
      messages: langMessages,
      silentTranslationWarn: process.env.VUE_APP_ENV === "production",
    });
  },
};
