const getDefaultState = () => {
  return {
    allCourses: [],
  };
};

const state = getDefaultState();

// getters
const getters = {
  getAllCourses: (state) => {
    return state.allCourses;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  setAllCourse(state, payload) {
    state.allCourses = payload;
  },
  concatAllCourse(state, payload) {
    state.allCourses = state.allCourses.concat(payload);
  },
  searchCourse(state, payload) {
    state.allCourses = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
