<template>
  <router-view />
</template>

<style lang="scss">
@import "@/assets/app.css";
#app {
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
