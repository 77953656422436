import { createApp } from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import bootstrap from "./bootstrap";
import "./index.css";
import { buildUrl } from "@/utils/urlUtils";
import FilledButton from "./components/Misc/Atoms/FilledButton.vue";
import OutlinedButton from "./components/Misc/Atoms/OutlinedButton.vue";
import BorderedButton from "./components/Misc/Atoms/BorderedButton.vue";
axios.defaults.baseURL = buildUrl("/api");
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

//Google Analytics
if (process.env.VUE_APP_ENV === "production") {
  bootstrap.initGA();
}

const i18n = bootstrap.initLangModule();
const localForage = bootstrap.initlocalForage();

const app = createApp(App);

app.config.globalProperties.$buildUrl = buildUrl;

app.use(router);
app.use(store);
app.use(i18n);
app.use(localForage);
app.mount("#app");
app
  .component("filled-button", FilledButton)
  .component("bordered-button", BorderedButton)
  .component("outlined-button", OutlinedButton);
