const baseUrl = process.env.VUE_APP_BASE_URL;

export const buildUrl = (path, queryParams = {}) => {
  // Append Path
  const url = new URL(`${baseUrl}${path}`);
  // Append query parameters
  Object.keys(queryParams).forEach((key) => {
    url.searchParams.append(key, queryParams[key]);
  });

  return url.toString();
};

export function linksToPath(path, links) {
  return links.map((item) => {
    if (!item.url) return item;

    let params = item.url.split("?")[1];
    params.replace("page=1", "");
    item.url = `${path}?${params}`;
    return item;
  });
}
