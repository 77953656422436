import localforage from "@/localForage";

const getDefaultState = () => {
  return {
    list: [],
    cartState: false,
  };
};

const state = getDefaultState();

// getters
const getters = {
  getCartState: (state) => {
    return state.cartState;
  },
  getList: (state) => {
    return [...state.list].reverse();
  },
  getNumberOfItems: (state) => {
    return state.list.length;
  },
  getTotalPrice: (state) => {
    return state.list.length == 0
      ? 0
      : state.list
          .reduce((t, { price, items }) => {
            if (!items) return t + price;

            return (
              t +
              price +
              items.reduce((subTotal, child) => subTotal + child.price, 0)
            );
          }, 0)
          .toFixed(2);
  },
};

// actions
const actions = {
  setList({ commit }, payload) {
    commit("setList", payload);
  },
  addItem({ commit }, payload) {
    commit("openTheCart");
    const alreadyInCart = state.list.some(
      (item) => item.id == payload.id && item.type == payload.type
    );
    if (alreadyInCart && !payload.items) return;
    const parsedPayload = {
      type: payload.type,
      id: payload.id,
      name: payload.name,
      price: payload.price,
      img: payload.img,
      items: payload.items,
      product_metas: payload.productMetas,
    };
    if (alreadyInCart) commit("updateItem", parsedPayload);
    else commit("addItem", parsedPayload);
  },
  removeItem({ commit }, payload) {
    commit("removeItem", payload);
  },
  clear({ commit }) {
    commit("clear");
  },
  changeCartState({ commit }) {
    commit("toggleCart");
  },
  closeCartState({ commit }) {
    commit("closeTheCart");
  },
};

// mutations
const mutations = {
  setList(state, payload) {
    state.list = payload;
    saveToLocalForage(state.list);
  },
  addItem(state, payload) {
    state.list.push(payload);
    saveToLocalForage(state.list);
  },
  setCartState(state, value) {
    state.cartState = value;
  },
  toggleCart(state) {
    state.cartState = !state.cartState;
  },
  closeTheCart(state) {
    if (state.cartState) {
      state.cartState = false;
    }
  },
  openTheCart(state) {
    state.cartState = true;
  },
  updateItem(state, payload) {
    state.list = state.list.map((item) => {
      if (!(item.id == payload.id && item.type == payload.type)) return item;
      return payload;
    });
    saveToLocalForage(state.list);
  },
  removeItem(state, payload) {
    state.list = state.list.filter(
      (item) => !(item.id == payload.id && item.type == payload.type)
    );
    saveToLocalForage(state.list);
  },
  clear(state) {
    localforage.removeItem("cart").then(() => {
      Object.assign(state, getDefaultState());
    });
  },
};

function saveToLocalForage(data) {
  localforage.setItem(
    "cart",
    JSON.stringify({
      items: data,
      timestamp: new Date().getTime(),
    })
  );
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
