import { createStore } from "vuex";
import cart from "./modules/cart";
import courses from "./modules/courses";
import theme from "./modules/theme";

const store = createStore({
  modules: {
    cart,
    courses,
    theme,
  },
});

export default store;
