import localforage from "@/localForage";

const availableThemes = {
  nightMode: "night-mode",
  dayMode: "day-mode",
};

const nightModeColors = {
  white: "#131619",
};

const lightModeColors = {
  white: "#FFFFFF",
};

const getDefaultState = () => {
  return {
    mode: localforage.getItem("theme")
      ? localforage.getItem("theme")
      : "day-mode",
    colors:
      localforage.getItem("theme") == "day-mode" ||
      localforage.getItem("theme") == null
        ? lightModeColors
        : nightModeColors,
  };
};

const state = getDefaultState();

// getters
const getters = {
  getThemeMode: (state) => {
    return state.mode;
  },
  getColors: (state) => {
    return state.colors;
  },
  isNightMode: (state) => {
    return state.mode == availableThemes.nightMode || state.mode == null;
  },
};

// actions
const actions = {
  updateThemeClass({ commit }) {
    commit("updateThemeClass");
  },
  setThemeToNightMode({ commit }) {
    commit("setThemeToNightMode");
    commit("updateThemeClass");
  },
  setThemeToDayMode({ commit }) {
    commit("setThemeToDayMode");
    commit("updateThemeClass");
  },
};

// mutations
const mutations = {
  updateThemeClass(state) {
    state.mode === null || state.mode === availableThemes.nightMode
      ? (document.documentElement.className = availableThemes.nightMode)
      : (document.documentElement.className = availableThemes.dayMode);
  },
  setThemeToNightMode(state) {
    state.mode = availableThemes.nightMode;
    state.colors = nightModeColors;
    localforage.setItem("theme", availableThemes.nightMode);
  },
  setThemeToDayMode(state) {
    state.mode = availableThemes.dayMode;
    state.colors = lightModeColors;
    localforage.setItem("theme", availableThemes.dayMode);
  },
  clear(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
