<template>
  <button
    class="rounded-3xl text-white h-12 md:h-10 font-bold"
    :class="[bgColor, width]"
  >
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      default: "bg-blue",
    },
    width: {
      type: String,
      default: "w-28",
    },
  },
};
</script>
