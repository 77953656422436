<template>
  <button
    class="rounded-3xl bg-transparent border-2 w-28 h-10 font-bold"
    :class="[borderColor, width, textColor]"
  >
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    borderColor: {
      type: String,
      default: "border-blue",
    },
    width: {
      type: String,
      default: "48",
    },
    textColor: {
      type: String,
      default: "text-blue",
    },
  },
};
</script>
